import React, { useRef } from "react";
import "../styles/Contact.css";
// import { Wrapper, Map, Marker } from "@googlemaps/react-wrapper";
import emailjs from '@emailjs/browser';
import ReCAPTCHA from "react-google-recaptcha";
// import Button from '@mui/material/Button';
import GoogleMap from "../components/gMap";

function Contact() {

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_sq8xu2h', 'template_92cvxtd', form.current, '2KFI31G5jr5e2CLS1')
      .then((result) => {
          console.log(result.text);
          console.log("message sent");
      }, (error) => {
          console.log(error.text);
      });
      form.current.reset();
  };
  
  return (
    <div className="contact" id="contact"
    >
      <div className="leftSide">
        <div>
          <p> Address: 8675309 California Street</p>
          <p> Redding, CA 96001</p>
          <p> Email:  jaysonsewell002@gmail.com</p>
          <p style={{textAlign:"center", borderStyle: 'solid'
        }}> Get Directions</p>
        </div>
        <div className="map">

          {/* map with text */}
        </div>
        {/* <GoogleMap></GoogleMap> */}
        
      {/* <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br> */}

        {/* <Button variant="contained"> Donation </Button> */}
      {/* <h3> QR Code Donations Go to the Redding Bitcoin Center </h3> */}

      </div>
      <div className="rightSide">
      
        <h1> Contact Us</h1>

        <form ref={form} onSubmit={sendEmail}>
          <label htmlFor="name">Full Name</label>
          <input name="name" placeholder="Enter full name..." type="text" />
          <label htmlFor="email">Email</label>
          <input name="email" placeholder="Enter email..." type="email" />
          <label htmlFor="message">Message</label>
          <textarea
            rows="6"
            placeholder="Enter message..."
            name="message"
            required
          ></textarea>
                    <ReCAPTCHA
          sitekey="6LewI-ooAAAAANlZu0Y9UJ7z57q7NVz4WcKmuh0M"
          // onChange={onChange}
          />
          <button type="submit" value="send message"> Send Message</button>
        </form>
      </div>
        <p>* Wanna Join Our Community? *</p>  
        <p> Fill Out Our Contact Form: </p>
        <li>*For A Website*</li>
        <li>*Hosting Plan*</li>
        <li>*Or Inquiries*</li> 
    </div>
  );
}

export default Contact;
